import React from "react";
import HomeIcons from "../components/HomeIcons";

function Home() {
  return (
    <>
      <HomeIcons />
    </>
  );
}

export default Home;
