import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Dock from "./components/Dock";

function App() {
  return (
    <>
      <Navbar />
      <Home />
      <Dock />
    </>
  );
}

export default App;
